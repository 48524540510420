import React from 'react';

const IconEmail = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="16px"
			height="16px"
			viewBox="0 0 16 16"
			version="1.1"
		>
			<g id="surface1">
				<path
					style={{
						fill: "none",
						strokeWidth: 2,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						stroke: "rgb(100%,100%,100%)",
						strokeOpacity: 1,
						strokeMiterlimit: 4
					}}
					d="M 21 7.998047 L 17.4375 9.978516 C 15.451172 11.080078 14.460938 11.630859 13.412109 11.847656 C 12.480469 12.041016 11.519531 12.041016 10.587891 11.847656 C 9.539062 11.630859 8.548828 11.080078 6.5625 9.978516 L 3 7.998047 M 6.199219 19.001953 L 17.800781 19.001953 C 18.919922 19.001953 19.482422 19.001953 19.910156 18.779297 C 20.285156 18.591797 20.589844 18.287109 20.783203 17.90625 C 21 17.478516 21 16.921875 21 15.802734 L 21 8.197266 C 21 7.078125 21 6.521484 20.783203 6.09375 C 20.589844 5.712891 20.285156 5.408203 19.910156 5.220703 C 19.482422 4.998047 18.919922 4.998047 17.800781 4.998047 L 6.199219 4.998047 C 5.080078 4.998047 4.517578 4.998047 4.089844 5.220703 C 3.714844 5.408203 3.410156 5.712891 3.216797 6.09375 C 3 6.521484 3 7.078125 3 8.197266 L 3 15.802734 C 3 16.921875 3 17.478516 3.216797 17.90625 C 3.410156 18.287109 3.714844 18.591797 4.089844 18.779297 C 4.517578 19.001953 5.080078 19.001953 6.199219 19.001953 Z M 6.199219 19.001953 "
					transform="matrix(0.666667,0,0,0.666667,0,0)"
				/>
			</g>
		</svg>
	);
};

export default IconEmail;