import React from 'react';

const IconMunicipalWater = () => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 512 512"
			xmlSpace="preserve"
			width="64px"
			height="64px"
			fill="#000000"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth={0}/>
			<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					style={{fill: "#94D1EA"}}
					d="M175.427,202.36c0,27.881-22.601,50.482-50.482,50.482S74.463,230.24,74.463,202.36 s50.482-97.281,50.482-97.281S175.427,174.479,175.427,202.36z"
				/>
				{" "}
				<path
					style={{fill: "#FFBA7A"}}
					d="M448.812,264.63c0-18.401-15.752-32.996-34.076-31.315c-0.165,0.015-0.33,0.031-0.497,0.048 l-60.519-0.019c-17.064-0.006-34.03,3.012-49.914,9.247c-0.096,0.038-0.193,0.075-0.289,0.113l-139.497,62.524 c-15.035,5.453-22.8,22.063-17.347,37.097c0.972,2.679,2.301,5.126,3.909,7.311l-111.3-0.628 c-15.993-0.069-29.015,12.837-29.085,28.83c-0.07,15.992,12.837,29.015,28.83,29.085l89.244-0.779 c28.822-0.186,57.31-6.186,83.758-17.642L350.89,328.36c8.113-3.942,16.8-6.839,25.738-8.06h40.54 c23.435,0,32.315-11.214,31.81-25.654L448.812,264.63z"
				/>
				{" "}
				<rect
					x="392.558"
					y="214.214"
					style={{fill: "#94D1EA"}}
					width="41.878"
					height="155.67"
				/>
				{" "}
				<rect
					x="434.435"
					y="214.214"
					style={{fill: "#EAE8E8"}}
					width="67.366"
					height="155.67"
				/>
				{" "}
				<g>
					{" "}
					<path
						style={{fill: "#5E282D"}}
						d="M124.945,263.041c33.461,0,60.681-27.221,60.681-60.681c0-30.322-47.066-95.902-52.433-103.28 c-1.919-2.639-4.985-4.2-8.248-4.2s-6.329,1.562-8.248,4.2c-5.367,7.378-52.433,72.958-52.433,103.28 C64.264,235.82,91.485,263.041,124.945,263.041z M124.945,122.811c18.131,26.587,40.283,64.127,40.283,79.549 c0,22.212-18.071,40.283-40.283,40.283s-40.283-18.071-40.283-40.283C84.662,186.938,106.815,149.398,124.945,122.811z"
					/>
					{" "}
					<path
						style={{fill: "#5E282D"}}
						d="M290.648,300.251l-1.989,0.883c-5.148,2.287-7.467,8.313-5.18,13.461 c1.688,3.803,5.418,6.061,9.327,6.061c1.382,0,2.788-0.283,4.135-0.88l1.989-0.883c5.148-2.287,7.467-8.313,5.18-13.461 C301.823,300.285,295.8,297.965,290.648,300.251z"
					/>
					{" "}
					<path
						style={{fill: "#5E282D"}}
						d="M501.801,380.084c5.632,0,10.199-4.566,10.199-10.199V214.211c0-5.633-4.567-10.199-10.199-10.199 H392.556c-5.632,0-10.199,4.566-10.199,10.199v8.949l-28.633-0.015c-0.021,0-0.04,0-0.06,0c-18.731,0-36.76,3.348-53.577,9.949 l-0.29,0.113c-0.152,0.06-0.303,0.122-0.451,0.19l-139.18,62.382c-9.657,3.613-17.367,10.732-21.741,20.084 c-3.475,7.432-4.517,15.575-3.083,23.487l-96.013-0.543c-0.058,0-0.113,0-0.172,0c-21.512,0-39.062,17.454-39.156,38.985 c-0.094,21.591,17.395,39.233,38.985,39.328c0.016,0,0.032,0,0.048,0c0.029,0,0.058,0,0.086,0l89.222-0.778 c30.362-0.196,59.884-6.414,87.746-18.481l138.858-60.141c0.136-0.059,0.27-0.12,0.404-0.186 c7.433-3.612,14.832-5.976,22.001-7.034h5.008v39.384c0,5.633,4.567,10.199,10.199,10.199L501.801,380.084L501.801,380.084z M376.629,310.102c-0.462,0-0.923,0.032-1.381,0.094c-9.448,1.291-19.071,4.283-28.61,8.892l-138.66,60.055 c-25.33,10.97-52.168,16.623-79.792,16.801l-89.171,0.778c-10.315-0.078-18.661-8.517-18.617-18.841 c0.045-10.315,8.452-18.676,18.746-18.676c0.027,0,0.055,0,0.082,0l106.966,0.604c7.265,7.149,17.135,11.269,27.458,11.269 c3.761,0,7.584-0.547,11.36-1.691c0.402-0.122,0.795-0.268,1.178-0.439l83.614-37.124c5.149-2.286,7.469-8.312,5.183-13.461 c-2.285-5.147-8.311-7.468-13.46-5.183l-82.977,36.842c-9.185,2.489-18.774-2.336-22.292-11.188 c-1.704-4.707-1.476-9.796,0.645-14.332c2.122-4.539,5.886-7.98,10.596-9.688c0.234-0.085,0.466-0.178,0.694-0.28l139.274-62.424 l0.067-0.027c14.441-5.668,29.96-8.542,46.13-8.542c0.017,0,0.036,0,0.053,0l28.642,0.015V310.1h-5.729V310.102z M402.756,224.41 h21.48v135.275h-21.48V224.41z M491.602,359.685h-46.969V224.41h46.969V359.685z"
					/>
					{" "}
				</g>
				{" "}
			</g>
		</svg>

	);
};

export default IconMunicipalWater;