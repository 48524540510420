import React from 'react';

const IconAddress = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="16px"
			height="16px"
			viewBox="0 0 16 16"
			version="1.1"
		>
			<g id="surface1">
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 8 0.5 C 4.964844 0.5 2.5 2.964844 2.5 5.996094 C 2.5 11.332031 7.6875 15.386719 7.6875 15.386719 C 7.871094 15.53125 8.128906 15.53125 8.3125 15.386719 C 8.3125 15.386719 13.5 11.332031 13.5 5.996094 C 13.5 2.964844 11.03125 0.5 8 0.5 Z M 8 1.5 C 10.488281 1.5 12.5 3.507812 12.5 5.996094 C 12.5 10.363281 8.550781 13.808594 8.003906 14.277344 C 7.457031 13.8125 3.5 10.363281 3.5 5.996094 C 3.5 3.507812 5.507812 1.5 8 1.5 Z M 8 1.5 "
				/>
				<path
					style={{
						stroke: "none",
						fillRule: "nonzero",
						fill: "rgb(100%,100%,100%)",
						fillOpacity: 1
					}}
					d="M 8 3.5 C 6.625 3.5 5.5 4.621094 5.5 5.996094 C 5.5 7.371094 6.625 8.5 8 8.5 C 9.375 8.5 10.5 7.371094 10.5 5.996094 C 10.5 4.621094 9.375 3.5 8 3.5 Z M 8 4.5 C 8.832031 4.5 9.5 5.164062 9.5 5.996094 C 9.5 6.832031 8.832031 7.5 8 7.5 C 7.164062 7.5 6.5 6.832031 6.5 5.996094 C 6.5 5.164062 7.164062 4.5 8 4.5 Z M 8 4.5 "
				/>
			</g>
		</svg>
	);
};

export default IconAddress;