import React from 'react';

const IconIrrigation = () => {
	return (
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 297.5 297.5"
			xmlSpace="preserve"
			width="64px"
			height="64px"
			fill="#000000"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth={0} />
			<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
			<g id="SVGRepo_iconCarrier">
				{" "}
				<g>
					{" "}
					<g id="XMLID_34_">
						{" "}
						<g>
							{" "}
							<path
								style={{ fill: "#BEBB5D" }}
								d="M190.92,219.581v48.92h-84.34v-48.92h16.15c5.45,0,9.88-4.43,9.88-9.89v-29.15h32.29v29.15 c0,5.46,4.42,9.89,9.88,9.89H190.92z"
							/>{" "}
							<rect
								x="106.58"
								y="141.501"
								style={{ fill: "#86A090" }}
								width="84.34"
								height="19.27"
							/>{" "}
							<path
								style={{ fill: "#1E918A" }}
								d="M40.43,122.021c0.51-0.06,1.01-0.11,1.5-0.15c-5.13,7.01-4.96,12.63-4.21,19.87l0.13,1.2 c0.33,3.37-0.96,4.76-1.96,5.51c-2.36,1.77-6.56,2.22-9.58,1.01c-4.81-1.92-7.16-7.4-5.24-12.21 C24.7,128.191,31.03,123.211,40.43,122.021z"
							/>{" "}
							<path
								style={{ fill: "#1E918A" }}
								d="M56.16,81.181c-5.55-0.73-9.56,0.97-14.29,3.46l-0.86,0.45c-2.04,1.06-2.97,0.49-3.42,0.22 c-1.62-0.98-2.99-3.62-2.99-5.75c0-3.45,2.81-6.26,6.26-6.26C48.66,73.301,53.35,76.971,56.16,81.181z"
							/>{" "}
							<path
								style={{ fill: "#1E918A" }}
								d="M84.57,25.461c7.26,6.53,9.75,14.2,7.62,23.43c-0.12,0.5-0.24,0.98-0.38,1.46 c-4.81-7.23-10.15-9.01-17.2-10.81l-1.17-0.3c-3.27-0.84-4.13-2.54-4.49-3.73c-0.85-2.83,0.19-6.93,2.36-9.35 c1.85-2.06,4.41-3.1,6.99-3.1C80.53,23.061,82.78,23.851,84.57,25.461z"
							/>{" "}
							<path
								style={{ fill: "#1E918A" }}
								d="M254.65,135.731c3.03,1.12,4.59,4.49,3.48,7.53c-2.5,6.78-7.13,9.82-11.7,11.04 c2.32-4.68,2.1-8.83,1.44-13.87l-0.12-0.94c-0.27-2.1,0.49-2.7,0.86-3c0.9-0.72,2.39-1.12,3.84-1.12 C253.23,135.371,253.99,135.491,254.65,135.731z"
							/>{" "}
							<path
								style={{ fill: "#1E918A" }}
								d="M244.94,77.481c1.8,2.71,2.24,6.92,0.99,9.59c-0.53,1.13-1.62,2.68-4.99,3.05l-1.2,0.12 c-7.26,0.77-12.82,1.77-18.65,8.3c-0.94-7.47,0.57-16.83,10.83-23.66c2.08-1.39,4.58-1.89,7.05-1.4 C241.43,73.971,243.55,75.401,244.94,77.481z"
							/>{" "}
							<path d="M287.37,268.501c5.46,0,9.88,4.42,9.88,9.88c0,5.46-4.42,9.89-9.88,9.89H10.14c-5.46,0-9.89-4.43-9.89-9.89 c0-5.46,4.43-9.88,9.89-9.88h76.68v-58.81c0-5.46,4.42-9.88,9.88-9.88h16.14v-19.27H96.7c-5.46,0-9.88-4.43-9.88-9.89v-39.04 c0-5.46,4.42-9.88,9.88-9.88h104.11c5.45,0,9.88,4.42,9.88,9.88v39.04c0,5.46-4.43,9.89-9.88,9.89h-16.15v19.27h16.15 c5.45,0,9.88,4.42,9.88,9.88v58.81H287.37z M190.92,268.501v-48.92h-16.14c-5.46,0-9.88-4.43-9.88-9.89v-29.15h-32.29v29.15 c0,5.46-4.43,9.89-9.88,9.89h-16.15v48.92H190.92z M190.92,160.771v-19.27h-84.34v19.27H190.92z" />{" "}
							<path d="M259.42,122.741c10.2,3.75,15.44,15.1,11.69,25.29c-6.02,16.38-19.67,20.82-30.72,20.82c-4,0-7.65-0.58-10.48-1.38 c-2.44-0.7-4.31-2.68-4.86-5.17c-0.55-2.48,0.31-5.07,2.24-6.73c7.74-6.67,7.57-7.95,6.86-13.34l-0.13-0.98 c-0.81-6.34,1.31-11.87,5.96-15.58C245.16,121.551,252.97,120.371,259.42,122.741z M258.13,143.261 c1.11-3.04-0.45-6.41-3.48-7.53c-0.66-0.24-1.42-0.36-2.2-0.36c-1.45,0-2.94,0.4-3.84,1.12c-0.37,0.3-1.13,0.9-0.86,3l0.12,0.94 c0.66,5.04,0.88,9.19-1.44,13.87C251,153.081,255.63,150.041,258.13,143.261z" />{" "}
							<path d="M256.45,69.811c4.51,6.76,5.32,16.05,2.01,23.12c-2.92,6.25-8.61,10.14-16.02,10.94l-1.25,0.14 c-7.15,0.75-9.56,1-16.01,13.51c-1.16,2.26-3.47,3.7-6.02,3.75c-0.04,0-0.08,0-0.13,0c-2.49,0-4.8-1.35-6.03-3.53 c-6.63-11.79-12.82-38.33,11.24-54.38c5.17-3.44,11.36-4.66,17.44-3.45C247.76,61.131,253.01,64.651,256.45,69.811z M245.93,87.071c1.25-2.67,0.81-6.88-0.99-9.59c-1.39-2.08-3.51-3.51-5.97-4c-2.47-0.49-4.97,0.01-7.05,1.4 c-10.26,6.83-11.77,16.19-10.83,23.66c5.83-6.53,11.39-7.53,18.65-8.3l1.2-0.12C244.31,89.751,245.4,88.201,245.93,87.071z" />{" "}
							<path d="M246.62,219.581c5.46,0,9.88,4.42,9.88,9.88c0,5.46-4.42,9.88-9.88,9.88c-5.46,0-9.89-4.42-9.89-9.88 c0-5.46,4.42-9.88,9.88-9.88H246.62z" />{" "}
							<path d="M116.16,89.111c5.46,0,9.88,4.43,9.88,9.89c0,5.45-4.42,9.88-9.88,9.88c-5.46,0-9.89-4.43-9.89-9.88 c0-5.46,4.42-9.89,9.88-9.89H116.16z" />{" "}
							<path d="M93.83,15.181c21.49,19.34,11.54,44.71,3.28,55.43c-1.32,1.71-3.36,2.69-5.48,2.69c-0.37,0-0.75-0.03-1.12-0.09 c-2.51-0.42-4.6-2.17-5.42-4.58c-4.58-13.31-6.92-13.91-13.89-15.68l-1.22-0.31c-7.22-1.87-12.29-6.54-14.28-13.14 c-2.25-7.47-0.11-16.55,5.32-22.59C69.59,7.391,84.3,6.611,93.83,15.181z M92.19,48.891c2.13-9.23-0.36-16.9-7.62-23.43 c-1.79-1.61-4.04-2.4-6.27-2.4c-2.58,0-5.14,1.04-6.99,3.1c-2.17,2.42-3.21,6.52-2.36,9.35c0.36,1.19,1.22,2.89,4.49,3.73 l1.17,0.3c7.05,1.8,12.39,3.58,17.2,10.81C91.95,49.871,92.07,49.391,92.19,48.891z" />{" "}
							<path d="M74.11,92.211c0.2,2.54-1.02,4.98-3.16,6.35c-1.14,0.73-2.44,1.1-3.74,1.1c-1.15,0-2.31-0.29-3.36-0.88 c-9.31-5.17-10.63-4.48-15.55-1.89l-0.91,0.47c-5.77,3-11.8,2.92-16.98-0.22c-5.77-3.5-9.65-10.57-9.65-17.58 c0-11.08,9.02-20.1,20.1-20.1C65.2,59.461,73.25,80.891,74.11,92.211z M41.87,84.641c4.73-2.49,8.74-4.19,14.29-3.46 c-2.81-4.21-7.5-7.88-15.3-7.88c-3.45,0-6.26,2.81-6.26,6.26c0,2.13,1.37,4.77,2.99,5.75c0.45,0.27,1.38,0.84,3.42-0.22 L41.87,84.641z" />{" "}
							<path d="M63.83,115.201c0.48,2.5-0.45,5.06-2.42,6.67c-10.91,8.89-10.66,11.3-9.92,18.45l0.12,1.25 c0.74,7.42-1.89,13.79-7.4,17.94c-3.91,2.93-9,4.47-14.11,4.47c-3.05,0-6.11-0.55-8.93-1.67c-11.89-4.76-17.7-18.3-12.95-30.19 c10.74-26.85,37.99-26.27,50.9-22.21C61.54,110.671,63.36,112.701,63.83,115.201z M41.93,121.871c-0.49,0.04-0.99,0.09-1.5,0.15 c-9.4,1.19-15.73,6.17-19.36,15.23c-1.92,4.81,0.43,10.29,5.24,12.21c3.02,1.21,7.22,0.76,9.58-1.01c1-0.75,2.29-2.14,1.96-5.51 l-0.13-1.2C36.97,134.501,36.8,128.881,41.93,121.871z" />{" "}
							<path d="M34.62,203.271c5.46,0,9.88,4.42,9.88,9.88c0,5.46-4.42,9.88-9.88,9.88s-9.89-4.42-9.89-9.88c0-5.46,4.42-9.88,9.88-9.88 H34.62z" />{" "}
						</g>{" "}
						<g> </g>{" "}
					</g>{" "}
				</g>{" "}
			</g>
		</svg>

	);
};

export default IconIrrigation;