import React from "react";
import IconWater from "./icons/IconWater";
import IconHouse from "./icons/IconHouse";
import IconBuilding from "./icons/IconBuilding";
import IconIrrigation from "./icons/IconIrrigation";
import IconDrip from "./icons/IconDrip";
import IconMunicipalWater from "./icons/IconMunicipalWater";

export const Services = () => {
  const data =   [
    {
      icon: <IconBuilding/>,
      name: "Բազմաբնակարան շենքերի կառուցման որակի խորհդատվական ծառայությունների մատուցում"
    },
    {
      icon: <IconHouse/>,
      name: "Սեփական տների և քաղաքից դուրս գտնվող տների կառուցման որակի խորհրդատվական ծառայությունների մատքուցում"
    },
    {
      icon: <IconWater/>,
      name: "Փոքր հիդրոէլեկտրակայանների և օրվա կարգավորման ջրամբարների կառուցման խորհրդատվական ծառայությունների մատուցում"
    },
    {
      icon: <IconIrrigation/>,
      name: "Ոռոգման համակարգերի կառուցման խորհրդատվական ծառայությունների մաըուցում"
    },
    {
      icon: <IconDrip/>,
      name: "Ջերմոցային տնտեսությունների և կաթիլային ոռոգման համակարգերի կառուցման որակի խորհրդատվական ծառայությունների մատուցում"
    },
    {
      icon: <IconMunicipalWater/>,
      name: "Քաղաքային ջրամատակարարման և ջրահեռացման ջրագծերի կառուցման որակի խորհրդատվական ծառայությունների մատուցում"
    }
  ];
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Մեր ծառայությունները</h2>
        </div>
        <div className="row">
          {data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <div className='d-flex justify-center'>
                    <div className='icon'>
                      {d.icon}
                    </div>
                  </div>
                  <div className="service-desc">
                  <h3>{d.name}</h3>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};
