import React from "react";
import IconPhone from "./icons/IconPhone";
import IconEmail from "./icons/IconEmail";
import IconAddress from "./icons/IconAddress";

export const Contact = (props) => {

	return (
		<div>
			<div id="contact">
				<div className="container">
					<div className="col-md-8">
						<div className="row">
							<div className="section-title">
								<h2>Եկեք մնանք կապի մեջ</h2>
							</div>
						</div>
					</div>
					<div className="col-md-8 img-container">
						<img src='img/contact.png'/>
					</div>
					<div className="col-md-3 col-md-offset-1 contact-info">
						<div className="contact-item">
							<h3>Կոնտակտային տվյալներ</h3>
							<div>
								<div className='d-flex items-center gap-10'>
									<IconAddress/> Հասցե
								</div>
								{props.data ? props.data.address : "loading"}
							</div>
						</div>
						<div className="contact-item">
							<div className='d-flex items-center gap-10'>
								<IconPhone/> Հեռախոս
							</div>
							<div>
								<a href={`tel: ${props.data.phone1}`}>
									{props.data ? props.data.phone1 : "loading"}
								</a>
							</div>
							<div>
								<a href={`tel: ${props.data.phone2}`}>
									{props.data ? props.data.phone2 : "loading"}
								</a>
							</div>
						</div>
						<div className="contact-item">
							<a href={`mailTo: ${props.data.email}`}>
								<div className='d-flex items-center gap-10'>
									<IconEmail/> Էլ․փոստ
								</div>
								{" "}
								{props.data ? props.data.email : "loading"}
							</a>
						</div>
					</div>
				</div>
			</div>
			<div id="footer">
				<div className="container text-center">
					<p>
						&copy; {new Date().getFullYear()} - MAHG - All Rights Reserved.
					</p>
				</div>
			</div>
		</div>
	);
};
