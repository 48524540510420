import React from "react";
import { Navigation } from "./components/Navigation";
import { Header } from "./components/Header";
import { About } from "./components/About";
import { Services } from "./components/Services";
import { Gallery } from "./components/Gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/Contact";
import jsonData from "./data/data.json";
import "./App.css";

const App = () => {

  return (
    <div>
      <Navigation />
      <Header data={jsonData.Header} />
      {/*<Features data={landingPageData.Features} />*/}
      <About data={jsonData.About} />
      <Services />
      <Gallery data={jsonData.Gallery} />
      {/*<Testimonials data={landingPageData.Testimonials} />*/}
      <Team data={jsonData.Team} />
      <Contact data={jsonData.Contact} />
    </div>
  );
};

export default App;
